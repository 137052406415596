
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { formatDate } from "@/lib/date";
import XDataTable from "../hoc/Table.vue";
import {valueOrDash} from "@/assets/scripts/utils";

@Component({
  components: { XDataTable },
  computed: { ...mapState({ accountingPoint: "accountingPoint" }) },
})
class AccountingPointInfoTable extends Vue {
  [x: string]: any;

  headers = [
    { text: "Тип ПУ", value: "type", width: "15%" },
    { text: "Заводской номер", value: "id", width: "10%" },
    { text: "Число тарифов", value: "totalTariffs", width: "10%" },
    { text: "Коэффициент трансформации", value: "coef", width: "10%" },
    { text: "Услуга", value: "service", width: "15%" },
    { text: "Дата следующей поверки", value: "nextCheck", width: "15%" },
    { text: "Срок действия", value: "term", width: "20%" },
  ];

  public get items(): Array<string[]> {
    const requiredKeys = [
      "тип",
      "заводскойномер",
      "тарифность",
      "коэфтр",
      "услуга",
      "датаследующейповерки",
      "датнач",
      "даткнц",
    ];

    if (this.accountingPoint.measuringComplex) {
      return this.accountingPoint.measuringComplex.map((item: any) => {
        const pointValue = requiredKeys.map(
          (key) => {
            switch (key) {
              case 'датаследующейповерки':
                return formatDate(item[key], 'full')
              default:
                return valueOrDash(item[key])
            }
          }
        );
        const lastTableTd = pointValue
          .slice(-2)
          .map((date) => formatDate(date, "full"))
          .join(" - ");

        return [...pointValue.slice(0, -2), lastTableTd];
      })
    }

    return [];
  }
}

export default AccountingPointInfoTable;
