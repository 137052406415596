
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { formatDate } from "@/lib/date";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: { XDataTable },
  computed: { ...mapState({ accountingPoint: "accountingPoint" }) },
})
class AccountingPointParamTable extends Vue {
  [x: string]: any;

  headers = [
    { text: "Наименование", value: "key", width: "30%" },
    { text: "Значение", value: "value", width: "50%" },
    { text: "Срок действия", value: "term", width: "20%" },
  ];

  public get items(): Array<string[]> {
    const requiredKeys = ["наименование", "значение", "датнач", "даткнц"];
    let pointValues: Array<string[]> = [];

    if (this.accountingPoint.params) {
      pointValues = this.accountingPoint.params.map(
        (param: { [x: string]: any }) => {
          const paramValues = requiredKeys.map((key) => param[key]);

          const lastTableTd = paramValues
            .slice(-2)
            .map((date) => formatDate(date, "full"))
            .join(" - ");

          return [...paramValues.slice(0, -2), lastTableTd];
        }
      );
    }

    return pointValues;
  }
}

export default AccountingPointParamTable;
