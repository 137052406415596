
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { formatDate } from "@/lib/date";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: { XDataTable },
  computed: { ...mapState({ accountingPoint: "accountingPoint" }) },
})
class AccountingPointOtherEquipmentTable extends Vue {
  [x: string]: any;

  headers = [
    { text: "Тип трансформатора", value: "type", width: "15%" },
    { text: "Заводской номер", value: "id", width: "15%" },
    { text: "Параметры", value: "param", width: "50%" },
    { text: "Срок действия", value: "term", width: "20%" },
  ];

  public get items(): Array<string[]> {
    const requiredKeys = [
      "наименование",
      "заводскойномер",
      "параметры",
      "датнач",
      "даткнц",
    ];

    if (this.accountingPoint.otherEquipment) {
      return this.accountingPoint.otherEquipment.map((item: any) => {
        const pointValue = requiredKeys.map(
          (key) => item[key]
        );
        const lastTableTd = pointValue
          .slice(-2)
          .map((date) => formatDate(date, "full"))
          .join(" - ");

        return [...pointValue.slice(0, -2), lastTableTd];
      })
    }
    return [];
  }
}

export default AccountingPointOtherEquipmentTable;
